import React, { PureComponent } from 'react';
import { ContentLoaderBase } from 'shared';

import cx from './SkeletonLoaderRegistrationFull.sss';

export class SkeletonLoaderRegistrationFull extends PureComponent {
    renderMobile = () => (
        <ContentLoaderBase
            backgroundColor="#ECEFF5"
            foregroundColor="#FFFFFF"
            height={1620}
            speed={1.5}
            title={null}
            uniqueKey="SkeletonLoaderRegistrationFullMobile"
            width={360}
        >
            <rect height="25" rx="0" ry="0" width="320" x="16" y="37" />
            <rect height="16" rx="0" ry="0" width="240" x="16" y="80" />
            <rect height="46" rx="0" ry="0" width="326" x="16" y="115" />

            <rect height="20" rx="0" ry="0" width="203" x="16" y="210" />

            <rect height="54" rx="0" ry="0" width="286" x="55" y="245" />
            <rect height="54" rx="0" ry="0" width="286" x="55" y="325" />
            <rect height="54" rx="0" ry="0" width="286" x="55" y="405" />
            <rect height="60" rx="0" ry="0" width="210" x="55" y="485" />
            <rect height="40" rx="0" ry="0" width="210" x="55" y="590" />

            <rect height="54" rx="0" ry="0" width="286" x="55" y="645" />

            <rect height="54" rx="0" ry="0" width="286" x="55" y="740" />
            <rect height="54" rx="0" ry="0" width="286" x="55" y="822" />
            <rect height="54" rx="0" ry="0" width="286" x="55" y="900" />
            <rect height="54" rx="0" ry="0" width="286" x="55" y="982" />

            <rect height="54" rx="0" ry="0" width="286" x="55" y="1102" />
            <rect height="54" rx="0" ry="0" width="286" x="55" y="1182" />
            <rect height="54" rx="0" ry="0" width="286" x="55" y="1260" />
            <rect height="60" rx="0" ry="0" width="286" x="55" y="1345" />

            <rect height="52" rx="0" ry="0" width="328" x="16" y="1465" />
            <rect height="50" rx="0" ry="0" width="328" x="16" y="1527" />
        </ContentLoaderBase>
    );

    renderLarge = () => (
        <ContentLoaderBase
            backgroundColor="#ECEFF5"
            foregroundColor="#FFFFFF"
            height={1460}
            speed={1.5}
            title={null}
            uniqueKey="SkeletonLoaderRegistrationFull"
            width={720}
        >
            <rect height="28" rx="0" ry="0" width="320" x="42" y="48" />

            <rect height="16" rx="0" ry="0" width="270" x="42" y="100" />

            <rect height="48" rx="0" ry="0" width="250" x="42" y="135" />

            <rect height="16" rx="0" ry="0" width="500" x="42" y="230" />
            <rect height="56" rx="0" ry="0" width="306" x="42" y="270" />
            <rect height="56" rx="0" ry="0" width="306" x="42" y="348" />
            <rect height="56" rx="0" ry="0" width="306" x="42" y="428" />
            <rect height="56" rx="0" ry="0" width="332" x="380" y="428" />
            <rect height="56" rx="0" ry="0" width="200" x="42" y="508" />

            <rect height="16" rx="0" ry="0" width="240" x="42" y="612" />
            <rect height="16" rx="0" ry="0" width="465" x="42" y="750" />
            <rect height="56" rx="0" ry="0" width="306" x="42" y="790" />
            <rect height="56" rx="0" ry="0" width="306" x="365" y="790" />
            <rect height="56" rx="0" ry="0" width="306" x="42" y="870" />
            <rect height="56" rx="0" ry="0" width="306" x="365" y="870" />

            <rect height="16" rx="0" ry="0" width="240" x="42" y="995" />
            <rect height="56" rx="0" ry="0" width="640" x="42" y="1035" />
            <rect height="56" rx="0" ry="0" width="306" x="42" y="1115" />
            <rect height="56" rx="0" ry="0" width="306" x="365" y="1115" />
            <rect height="56" rx="0" ry="0" width="306" x="42" y="1115" />
            <rect height="56" rx="0" ry="0" width="306" x="42" y="1195" />

            <rect height="40" rx="0" ry="0" width="635" x="42" y="1288" />
        </ContentLoaderBase>
    );

    render() {
        return (
            <div className={cx('SkeletonLoaderRegistrationFull')}>
                <div className={cx('desktop')}>{this.renderLarge()}</div>
                <div className={cx('mobile')}>{this.renderMobile()}</div>
            </div>
        );
    }
}
