import React, { PureComponent } from 'react';
import { ContentLoaderBase } from 'shared';

import cx from './SkeletonLoaderRegistration.sss';

export default class SkeletonLoaderRegistration extends PureComponent {
    renderMobile = () => (
        <ContentLoaderBase
            backgroundColor="#ECEFF5"
            foregroundColor="#FFFFFF"
            height={620}
            speed={1.5}
            title={null}
            uniqueKey="SkeletonLoaderRegistrationMobile"
            width={360}
        >
            <rect height="32" rx="0" ry="0" width="330" x="16" y="20" />
            <rect height="50" rx="0" ry="0" width="330" x="16" y="72" />

            <rect height="56" rx="0" ry="0" width="330" x="16" y="176" />
            <rect height="56" rx="0" ry="0" width="330" x="16" y="252" />
            <rect height="48" rx="0" ry="0" width="330" x="16" y="328" />

            <rect height="18" rx="0" ry="0" width="160" x="16" y="408" />
            <rect height="48" rx="0" ry="0" width="330" x="16" y="438" />

            <rect height="48" rx="0" ry="0" width="292" x="16" y="512" />

            <rect height="16" rx="0" ry="0" width="292" x="16" y="568" />

            <rect height="16" rx="0" ry="0" width="200" x="16" y="596" />
        </ContentLoaderBase>
    );

    renderLarge = () => {
        return (
            <>
                <div className={cx('form')}>
                    <ContentLoaderBase
                        backgroundColor="#ECEFF5"
                        foregroundColor="#FFFFFF"
                        height={684}
                        speed={1.5}
                        title={null}
                        uniqueKey="SkeletonLoaderRegistration"
                        width={360}
                    >
                        <rect
                            height="64"
                            rx="0"
                            ry="0"
                            width="200"
                            x="34"
                            y="46"
                        />

                        <rect
                            height="50"
                            rx="0"
                            ry="0"
                            width="296"
                            x="34"
                            y="134"
                        />

                        <rect
                            height="55"
                            rx="0"
                            ry="0"
                            width="296"
                            x="34"
                            y="208"
                        />
                        <rect
                            height="55"
                            rx="0"
                            ry="0"
                            width="296"
                            x="34"
                            y="288"
                        />

                        <rect
                            height="48"
                            rx="0"
                            ry="0"
                            width="296"
                            x="34"
                            y="368"
                        />

                        <rect
                            height="16"
                            rx="0"
                            ry="0"
                            width="160"
                            x="34"
                            y="446"
                        />

                        <rect
                            height="48"
                            rx="0"
                            ry="0"
                            width="48"
                            x="34"
                            y="476"
                        />
                        <rect
                            height="48"
                            rx="0"
                            ry="0"
                            width="48"
                            x="92"
                            y="476"
                        />
                        <rect
                            height="48"
                            rx="0"
                            ry="0"
                            width="48"
                            x="152"
                            y="476"
                        />

                        <rect
                            height="48"
                            rx="0"
                            ry="0"
                            width="292"
                            x="34"
                            y="550"
                        />

                        <rect
                            height="16"
                            rx="0"
                            ry="0"
                            width="292"
                            x="34"
                            y="610"
                        />

                        <rect
                            height="16"
                            rx="0"
                            ry="0"
                            width="200"
                            x="34"
                            y="632"
                        />
                    </ContentLoaderBase>
                </div>
            </>
        );
    };

    render() {
        return (
            <div className={cx('SkeletonLoaderRegistration')}>
                <div className={cx('desktop')}>{this.renderLarge()}</div>
                <div className={cx('mobile')}>{this.renderMobile()}</div>
            </div>
        );
    }
}
